<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<BRow>
			<BCol>
				<PeopleOnYourPlanList
					automation-id="participantsPage"
					:participants="isEnglish ? participants : participantsFr"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<UpdateParticipantsForm />
			</BCol>
		</BRow>
	</ManageYourAccountTemplate>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';
import { BRow, BCol } from 'bootstrap-vue';
import * as Routes from '@/constants/Routes.js';
import Dependent from '@/models/Dependent.js';
import PeopleOnYourPlanList from '@/components/manage-your-account/people-on-your-plan/PeopleOnYourPlanList.vue';
import UpdateParticipantsForm from '@/components/manage-your-account/people-on-your-plan/UpdateParticipantsForm.vue';
import { formatDateForUi } from '@/utils/date';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'PeopleOnYourPlanPage',
	components: {
		PeopleOnYourPlanList,
		UpdateParticipantsForm,
		BRow,
		BCol,
		ManageYourAccountTemplate
	},
	mixins: [BreadcrumbsManager]
})
export default class PeopleOnYourPlanPage extends Vue {
	participants = [];
	participantsFr = [];

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	// Set the path for the BreadCrumbs Manager.
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.participants', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: Routes.MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.participants', 'fr') }
			]
		);
	}

	beforeCreate() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		Dependent.getActiveDependents(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					var self = this;
					response.data.forEach(function (participant) {
						self.participants.push({
							name: participant.firstName + ' ' + participant.lastName,
							dateOfBirth: formatDateForUi(participant.dateOfBirth, 'en')
						});
						self.participantsFr.push({
							nom: participant.firstName + ' ' + participant.lastName,
							date_de_naissance: formatDateForUi(participant.dateOfBirth, 'fr')
						});
					});
					// Need to add this in for accessiblity,
					if (!this.participants) {
						this.participants = [
							{
								name: '',
								date_of_birth: ''
							}
						];
					}
					if (!this.participantsFr) {
						this.participantsFr = [
							{
								nom: '',
								date_de_naissance: ''
							}
						];
					}
				} else {
					this.$store.dispatch('error', {
						name: 'participants-error',
						title: this.$t('error.wentWrongHeader'),
						err: this.$t('error.wentWrong')
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>

<style lang="scss" scoped>
.participants {
	margin-left: 5%;
	margin-right: 5%;
	@media (max-width: 500px) {
		margin-left: 0px;
		margin-right: 0px;
	}
	@media (width: 540px) {
		max-width: 540px;
	}
}
.title {
	font-size: $header-main-font-size;
	margin-bottom: 0;
	@media (max-width: 500px) {
		line-height: 1.3em;
	}
}
.subtitle {
	font-size: $header-sub-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	margin-bottom: 25px;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
        "title": "Account Information",
		"breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"participants": "People on your plan"
		},
		"error": {
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
	},
	"fr": {
        "title": "Renseignements sur le compte",
		"breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"participants": "Personnes assurées"
		},
		"error": {
			"wentWrongHeader": "Une erreur s’est produite",
			"wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
		}
	}
}
</i18n>
