<template>
	<BaseCard class="participant">
		<BaseTable stacked="sm" primary-key="id" :items="participants" caption-top>
			<template #table-caption>
				<h2 id="planTitle" class="plan-title">{{ $t('title') }}</h2>
			</template>
		</BaseTable>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseCard from '@/components/common/card/BaseCard';
import BaseTable from '@/components/common/base/BaseTable';

// @vue/component
@Component({
	name: 'PeopleOnYourPlanList',
	components: {
		BaseCard,
		BaseTable
	},
	props: {
		participants: {
			type: Array,
			required: true,
			defaultValue: null
		}
	}
})
export default class PeopleOnYourPlanList extends Vue {}
</script>

<style lang="scss" scoped>
::v-deep .memberIdColumn {
	display: none !important;
}
h2 {
	color: $black;
}
.plan-title {
	font-size: $header-main-font-size;
	line-height: 1.3em;
}
.hidden {
	display: none;
}
</style>

<i18n>
{
    "en": {
		"title": "People on your plan"
    },
    "fr": {
		"title": "Personnes assurées"
    }
}
</i18n>
