<template>
	<BaseCard class="base-card" automation-id="base-card">
		<BRow>
			<BCol class="h4" cols="12" lg="11" md="11" sm="12">
				{{ $t('booklet.title') }}
			</BCol>
		</BRow>
		<BRow>
			<BCol v-if="isIndividual" cols="12" lg="11" md="11" sm="12">
				{{ $t('booklet.descriptionOne') }}
				<BLink id="appLink" class="find-form-link" :href="findAForm" target="_blank">{{
					$t('booklet.link')
				}}</BLink>
				{{ $t('booklet.descriptionTwo') }}
			</BCol>
			<BCol v-else cols="12" lg="11" md="11" sm="12">
				{{ $t('booklet.groupText') }}
			</BCol>
		</BRow></BaseCard
	>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseCard from '@/components/common/card/BaseCard';
import { BRow, BCol, BLink } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'UpdateParticipantsForm',
	components: {
		BaseCard,
		BRow,
		BCol,
		BLink
	}
})
export default class UpdateParticipantsForm extends Vue {
	defaultBrand = 'MBC';

	securityPolicy = null;
	async beforeCreate() {
		this.securityPolicy = await JSON.parse(sessionStorage.getItem('securityPolicy'));
	}

	get isIndividual() {
		return this.securityPolicy ? this.securityPolicy.individual : false;
	}

	get branding() {
		if (this.securityPolicy) {
			return this.securityPolicy.brand;
		}
		return this.defaultBrand;
	}

	get findAForm() {
		const locale = this.$store.state.i18n.locale;
		if (this.branding === 'SBC') {
			return 'https://www.sk.bluecross.ca/customer-service/forms/';
		} else if (this.branding === 'QBC') {
			return locale === 'en'
				? 'https://qc.bluecross.ca/client-care/member-services/health-claim-forms'
				: 'https://qc.croixbleue.ca/service-a-la-clientele/services-aux-membres/formulaires-assurance-sante?_gl=1*yq2zyw*_ga*MTUxMjg2Mzg3OS4xNTk0NjY4MDUy*_ga_K5L0PY8G9S*MTY2OTA1MDczMS4zNC4wLjE2NjkwNTA3MzIuNTkuMC4w&_ga=2.235093633.185945953.1669042840-1512863879.1594668052';
		} else if (this.branding === 'OBC') {
			return 'https://on.bluecross.ca/client-care/member-services/health-claim-forms';
		} else {
			return locale === 'en'
				? 'https://www.medaviebc.ca/en/members/forms'
				: 'https://www.medaviebc.ca/fr/adherents/formulaires';
		}
	}
}
</script>

<i18n>
{
	"en": {
		"booklet": {
			"title": "Need to make some changes to the people on your plan?",
			"descriptionOne": "As a personal plan member, you can search for the form you need to update or remove dependants and people on your plan on our",
			"link":  "Find a Form",
			"descriptionTwo": "page.",
			"groupText": "Reach out to your group administrator or human resources representative to help get your changes processed. Currently, we are not able to make changes to dependants and people on your plan online."
		}
	},
	"fr": {
		"booklet": {
			"title": "Vous devez modifier les personnes assurées par votre régime?",
			"descriptionOne": "Les adhérents de l’ assurance individuelle peuvent obtenir le formulaire nécessaire pour modifier ou retirer les personnes à charge assurées par leur régime sur notre page",
			"link":  "Trouver un formulaire",
			"descriptionTwo": ". ",
			"groupText": "Communiquez avec votre administrateur(trice) de régime ou votre représentant(e) des ressources humaines qui pourra vous aider à appliquer les changements. Il n'est pas possible d'apporter des changements en ligne aux personnes à charge assurées par votre régime pour le moment."
		}
	}
}
</i18n>
